import React  from "react";
import { useEffect, useState } from "react";
import { ReactTyped } from "react-typed";




const DeviceError = () => {
    const [showIcon, setShowIcon] = useState(false);


    useEffect(() => {
        const timer = setTimeout(() => {
            setShowIcon(true);
        }, 3000);
        return () => clearTimeout(timer)
     }, []);

    return(
    <div className="mobile">
        {showIcon && <img className="mobileIcon" src="/images/apologize.png" alt="Sorry" />}
        <div className="typewriter-container-mobile">
        <ReactTyped
        strings={[
            'Sorry! <br /> This site is still being optimised for mobile platforms.<br /> Please return on a desktop. '
        ]}
        typeSpeed={20} /></div>
    </div>
    )

};

export default DeviceError;

